import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { alertMessage, selectErrors, selectIsExpire, selectTimeUntilExpire, selectCameraAddress, selectCameraLocation, selectTimeSinceAlert, selectAlertTitle, selectAlertActions, selectAlertId, addAction, getTimeSinceAlert, selectCameraLatitude, selectCameraLongitude, getDetailsAlertAsync, changeAlertStatusAsync, declareAlertResolvedAsync, selectAlertStatus, selectCameraId, selectEdgeDeviceId, selectSnapshotPath, selectCreateAt, selectVideoPath, selectIsResolved } from "./alertsSlice"
import { MY_SERVER } from '../../app/globe'
import "./AlertDetails.css"
import { Map } from '../Map/Map'
import { Viewer } from '../3d_viewer/Viewer';
import share_image from './static/share.png'
import cloche_image from './static/cloche.png'
import undo_arrow from './static/undo_arrow.png'
import check_mark from './static/check_mark.png'
import { useTranslation } from "react-i18next";

// to test issues 


// need url like this https://my-website.com?id=cdf2de0664794807bf3444ba81a8c2fd
export const AlertDetails = () => {

    const { t, i18n } = useTranslation('common');

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("id")
    const dispatch = useDispatch();
    const timeUntilExpire = useSelector(selectTimeUntilExpire)
    const isExpire = useSelector(selectIsExpire)
    const title = new Date(useSelector(selectAlertTitle)).toLocaleString()
    const timeSinceAlert = useSelector(selectTimeSinceAlert)
    const alertId = useSelector(selectAlertId);
    const alertCreateAt = new Date(useSelector(selectCreateAt)).toLocaleString();
    const alertstatus = useSelector(selectAlertStatus);
    const cameraId = useSelector(selectCameraId);
    const snapshot = useSelector(selectSnapshotPath);
    const video = useSelector(selectVideoPath);
    const isResolvedBack = useSelector(selectIsResolved)
    const [isResolvedUser, setIsResolvedUser] = useState(false)
    const device_id = useSelector(selectEdgeDeviceId)
    const camera_latitude = useSelector(selectCameraLatitude)
    const camera_longitude = useSelector(selectCameraLongitude)
    const alertActions = useSelector(selectAlertActions)
    const [answer, setanswer] = useState(false)
    const cameraAddress = useSelector(selectCameraAddress)
    const cameraLocation = useSelector(selectCameraLocation)
    const errors = useSelector(selectErrors)
    const [firstMessageSend, setFirstMessageSend] = useState(false)
    const [showFullSnapshot, setShowFullSnapshot] = useState(false);
    const [buttonYesClicked, setbuttonYesClicked] = useState(null)
    const [buttonNoClicked, setbuttonNoClicked] = useState(null)


    const handleClickSnapshot = () => {
        setShowFullSnapshot(!showFullSnapshot);

    }

    useEffect(() => {
        dispatch(getDetailsAlertAsync(token))

    }, [])

    useEffect(() => {
        setInterval(() => {
            dispatch(getTimeSinceAlert())
        }, 1000);
    }, [])

    return (
        <div >


            {errors != 404 ? <div> {!isExpire ? <div>
                <h1 className="AD-title">
                    {t('alerts.alert', { framework: 'React' })} &rsaquo; {title} 
                </h1>

                <div className="AD-div-alertDetails AD-gap-40">
                    <div className="AD-w-700">
                        <div className="AD-content-box AD-box-shadow AD-space-between">
                            <div className="AD-flex AD-gap-20 AD-justify-between">
                                <h5 className="AD-title">{cameraAddress}</h5>
                            </div>
                            <div className="AD-video">
                                <video id="alert_video" src={video} controls autoPlay muted loop ></video>
                            </div>
                        </div>
                        <div id="is_resolved_status" className={
                            `${isResolvedBack == false ? "AD-emergency-message" : "AD-resolved-message"} 
                            AD-mt-16 AD-text-center AD-strong AD-box-shadow AD-space-between`
                            }>
                                {!isResolvedBack ?
                                t('alerts.question', { framework: 'React' })
                                 :
                                t('alerts.resolved', { framework: 'React' })}
                        </div>

                        <div>
                            {!isResolvedBack ? <div >

                                <div className="AD-gap-buttons AD-flex AD-justify-around AD-space-between" >
                                    <button id='yes_btn' style={{ borderRadius: "20px" }}
                                        className={
                                            `AD-button-red AD-flex 
                                            ${buttonYesClicked == false ? 'AD-unclicked-button'
                                             : 
                                             buttonYesClicked == true ? 'AD-clicked-button'
                                            :
                                             ''
                                            }`}
                                        onClick={buttonYesClicked != true ? () => {
                                            dispatch(changeAlertStatusAsync({ "alert_token": token, "alert_status": "is_emergency" }));
                                            setanswer(true)
                                            setbuttonYesClicked(true)
                                            setbuttonNoClicked(false)
                                            dispatch(addAction({ "alert_id": alertId, "action": "The alert was declared as EMERGENCY" }))
                                            setFirstMessageSend(true);


                                        } : ''}>
                                        <div>
                                            {t('alerts.yes', { framework: 'React' })}
                                        </div>
                                        <img style={{ marginLeft: "10px" }} src={share_image} />
                                    </button>

                                    <button id='no_btn'
                                        className={
                                            `AD-button-green 
                                            ${buttonNoClicked == false ? 'AD-unclicked-button' 
                                            : 
                                            buttonNoClicked == true ? 'AD-clicked-button' 
                                            : 
                                            ''
                                        }`}
                                        onClick={buttonNoClicked != true ? () => {
                                            dispatch(changeAlertStatusAsync({ "alert_token": token, "alert_status": "is_not_emergency" }));
                                            setbuttonYesClicked(false)
                                            setbuttonNoClicked(true)
                                            setanswer(true)
                                            dispatch(addAction({ "alert_id": alertId, "action": "The alert was declared as NOT EMERGENCY" }))
                                        } : ''}>
                                            
                                        {t('alerts.no', { framework: 'React' })}
                                    </button>
                                </div>
                            </div> : ""}

                            {alertActions.map((action, i) => 
                            <div className='AD-space-between-actions'>
                                <div className="AD-flex AD-box-shadow AD-my-auto "
                                 style={{ backgroudColor: ' #3f7eb22f ', color: "#ffffff" }}>
                                    <a className="AD-content-box  AD-flex-1" >
                                        <div className="AD-flex AD-justify-between" style={{ alignItems: 'center' }}>
                                            <div id='image' >
                                                <img className='AD-text-start'
                                                    src={
                                                    action.actions == "The alert was declared as EMERGENCY" ? cloche_image
                                                     : 
                                                    action.actions == "The alert was declared as NOT EMERGENCY" ? check_mark
                                                    : 
                                                    action.actions == "UNDO" ? undo_arrow
                                                    : 
                                                    ""} alt="User" />

                                                {/* <strong style={{marginLeft:'20px',  width: '200px'}}  > action {alertActions.length - i }</strong> */}
                                            </div>
                                            <div style={{ paddingLeft: "12%" }}>
                                                <strong className="AD-my-auto AD-text-center "  >
                                                    {action.actions == "The alert was declared as EMERGENCY" ? t('alerts.declared_emergency', { framework: 'React' })
                                                    : 
                                                    action.actions == "The alert was declared as NOT EMERGENCY" ?  t('alerts.declared_unemergency', { framework: 'React' })
                                                    : 
                                                    action.actions == "UNDO" ? t('alerts.undo', { framework: 'React' })
                                                    : 
                                                    ""}</strong>
                                            </div>
                                            <div className="AD-flex AD-gap-8">
                                                <span className="AD-my-auto AD-light-text AD-text-right " >
                                                    {action.action_date} 
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>)}

                            <div className="AD-content-box AD-box-shadow AD-space-between ">
                                <div className="AD-flex AD-justify-between" style={{ alignItems: 'center' }}>
                                    <img className='AD-text-start'
                                        src={cloche_image} alt="User" />
                                    <strong className="AD-text-center" style={{
                                         width: "200px", 
                                         color: "#ffffff", 
                                         paddingLeft: "12%" }}>
                                        {t('alerts.alert', { framework: 'React' })} 
                                    </strong>

                                    <div className="AD-flex AD-gap-8">
                                        <span className="AD-my-auto AD-light-text AD-text-right AD-alert-created-on" >
                                            {alertCreateAt} 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 900 ? 
                        <div>
                            {!isResolvedBack ? 
                            <div>
                                {answer ?
                                    <div>
                                        <button
                                            name='resolve'
                                            style={{ marginTop: "30px", marginBottom: "30px" }}
                                            className='AD-button-resolve' id="resolved_btn"

                                            onClick={() => {
                                                dispatch(declareAlertResolvedAsync({
                                                    "alert_token": token,
                                                    "alert_status": `${buttonYesClicked == true ? 'is_emergency_resolved' : "is_not_emergency_resolved"}`,
                                                    "actions": alertActions
                                                }));
                                                setIsResolvedUser(true)
                                            }}>

                                            {t('alerts.resolve', { framework: 'React' })}
                                        </button>
                                    </div>
                                    : ""}
                            </div>
                                : ""}
                        </div>
                            : ''}
                    </div>
                    <div className='AD-div-column' >
                        <div className="AD-map-iframe AD-box-shadow">
                            <Map lat={camera_latitude} lng={camera_longitude}></Map>
                        </div>


                        <div className="AD-box-shadow">
                            <img id="myImg_ezechiel" src={snapshot} 
                            className="AD-rounded AD-flex AD-snapshot_tall" 
                            alt="Demo" 
                            onClick={handleClickSnapshot} />
                            {showFullSnapshot && <div className={`AD-fullscreen ${showFullSnapshot ? 'active' : ''}`}>
                                <img src={snapshot} alt="Demo" onClick={handleClickSnapshot} />
                            </div>}
                        </div>
                    </div>
                    <div className='AD-div-column'>
                        <div className="AD-map-iframe AD-box-shadow ">
                            <Viewer lat={camera_latitude} lng={camera_longitude}></Viewer>
                        </div>

                        <div className="AD-content-box-rounded AD-box-shadow AD-tall-time-since-alert ">
                            <h6 className="AD-title">
                                {t('alerts.time_since_alert', { framework: 'React' })} 
                            </h6>
                            <hr />
                            <span id="difference_time">
                                {timeSinceAlert}
                            </span>
                        </div>
                        {window.innerWidth >= 900 ?
                            <div>
                                {!isResolvedBack ?
                                    <div>
                                        {answer ?
                                            <div>
                                                <button
                                                    name='resolve'
                                                    style={{ marginTop: "30px", marginBottom: "30px" }}
                                                    className='AD-button-resolve' id="resolved_btn"
                                                    onClick={() => {
                                                        dispatch(declareAlertResolvedAsync({
                                                            "alert_token": token,
                                                            "alert_status": `${buttonYesClicked == true ? 'is_emergency_resolved' : "is_not_emergency_resolved"}`,
                                                            "actions": alertActions
                                                        }));
                                                        setIsResolvedUser(true)
                                                    }}>

                                                    {t('alerts.resolve', { framework: 'React' })}
                                                </button>
                                            </div>
                                            : ""}
                                    </div>
                                    : ""}
                            </div>
                            : ''}

                    </div>

                </div>
            </div> :
                <div>
                    <h1 className="AD-title">
                        {t('alerts.expire', { framework: 'React' })} 
                    </h1>
                </div>}
            </div> :
                <div>
                    <h1 className="AD-title">
                        {t('alerts.not_found', { framework: 'React' })} 
                    </h1>
                </div>}
        </div>
    );
}
