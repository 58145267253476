// localhost
// export const MY_SERVER="http://127.0.0.1:5000"

// dev
// export const MY_SERVER="https://flask.victoriousbay-e364aa43.eastus2.azurecontainerapps.io"

// fr-prd
// export const MY_SERVER="https://fr-prd-flask-app2--0000003.calmrock-df618c93.francecentral.azurecontainerapps.io"

// require('dotenv').config();
export const MY_SERVER=process.env.REACT_APP_FLASK_URL