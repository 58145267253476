import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { MY_SERVER } from '../../app/globe'
import "./Header.css"
import { selectCameraAddress, selectCameraLocation, selectIsResolved } from '../alerts/alertsSlice'
import { selectCameraAddressVideoFile, selectCameraLocationVideoFile } from '../videoFile/videoFileSlice'
import { selectCameraAddressSnapshotFile, selectCameraLocationSnapshotFile } from '../snapshotFile/snapshotFileSlice'

import { selectCurrentTime, getCurrentTime } from './headerSlice'
import blue_gif from './static/AiLert_blue.gif'
import red_gif from './static/AiLert_red.gif'
import ailert_logo from './static/AiLert_UI_logo.png'
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import arrow from './static/new_arrow.svg'
import Grid from '@mui/material/Grid';




export const Header = () => {
    const { t, i18n } = useTranslation('common');


    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };


    const alertResolved = useSelector(selectIsResolved)
    const cameraAddress = useSelector(selectCameraAddress)
    const cameraLocation = useSelector(selectCameraLocation)
    const cameraAddressVideoFile = useSelector(selectCameraAddressVideoFile)
    const cameraLocationVideoFile = useSelector(selectCameraLocationVideoFile)
    const cameraAddressSnapshotFile = useSelector(selectCameraAddressSnapshotFile)
    const cameraLocationSnapshotFile = useSelector(selectCameraLocationSnapshotFile)
    const currentTime = new Date(useSelector(selectCurrentTime)).toLocaleString(undefined, options)
    const [date, setDate] = useState(new Date());
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const languages = [
        
        {
            id: 'en',
            name: 'EN'
        },
        {
            id: 'es',
            name: 'ES'
        },
        {
            id: 'fr',
            name: 'FR'
        },
        {
            id: 'gr',
            name: 'GR'
        }

    ]

    const [languageSelected, setLanguageSelected] = useState('EN')
    const change_language = (language) => {

        i18n.changeLanguage(`${language.id}`)
        setLanguageSelected(language.name)

    }



    return (
        <>
        {window.innerWidth > 767 ? 
        <>
        <Grid container spacing={1} >
                <Grid item xs={2}>
                    <img className='logo' src={ailert_logo} alt="Logo" />
                </Grid>
                
                <Grid item xs={4} className='camera-info'  >
                    <div>
                <div>{t('header.location', { framework: 'React' })} : {cameraLocation ? cameraLocation : cameraLocationVideoFile ? cameraLocationVideoFile : cameraLocationSnapshotFile}</div>
                <div>{t('header.address', { framework: 'React' })}  : {cameraAddress ? cameraAddress : cameraAddressVideoFile ? cameraAddressVideoFile : cameraAddressSnapshotFile}</div>
                </div>

                </Grid>


                <Grid item xs={6} sx={{ paddingRight: '3vh', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                {window.innerWidth > 767 ? <div className='gif_position'>
                     <img className='gif_tall' id='alert_statue'
                         src={alertResolved == false ? red_gif : blue_gif} alt="Logo" />

                 </div> : ''}
                    <div>
                        <div style={{ color: 'white', justifySelf: 'center', fontSize: '3vh', paddingBottom: '2vh' }}>
                            {date.toLocaleDateString('en-US', options)} {date.toLocaleTimeString()}
                        </div>
                      

                        <div onClick={handleClick} style={{width:'20%', filter: 'drop-shadow(0 0 10px #000000)',  display: 'flex', alignItems: 'center', color: 'white' }}>

                            <a  style={{ color: 'white', fontSize: '3vh' }}>
                                {languageSelected}
                            </a>
                            {/* <div>Languges</div> */}
                            <img  src={arrow} style={{ paddingTop: '0.5vh' }} alt="User" />
                            <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom' sx={{ zIndex: 9999 }}>
                                <Box className='H-Logout-button' >
                                    {languages.map((language) =>
                                        <div className='button-language' onClick={() => change_language(language)}>
                                            <a> {language.name} </a>
                                        </div>
                                    )}
                                </Box>
                            </Popper>

                        </div>

                    </div>
                    
                </Grid>

            </Grid>
            </> : 

<>
<Grid container spacing={1} >
        <Grid item xs={4}>
            <img className='logo' src={ailert_logo} alt="Logo" />
        </Grid>
        
        <Grid item xs={8} className='camera-info'  >
        <div>{t('header.location', { framework: 'React' })} : {cameraLocation ? cameraLocation : cameraLocationVideoFile ? cameraLocationVideoFile : cameraLocationSnapshotFile}</div>
        <div>{t('header.address', { framework: 'React' })}  : {cameraAddress ? cameraAddress : cameraAddressVideoFile ? cameraAddressVideoFile : cameraAddressSnapshotFile}</div>
        <div>
                <div style={{ color: 'white', justifySelf: 'center', fontSize: '2vh' }}>
                    {date.toLocaleDateString('en-US', options)} {date.toLocaleTimeString()}
                </div>
              

                <div onClick={handleClick} style={{width:'20%', filter: 'drop-shadow(0 0 10px #000000)',  display: 'flex', alignItems: 'center', color: 'white' }}>

                    <a  style={{ color: 'white', fontSize: '2vh' }}>
                        {languageSelected}
                    </a>
                    {/* <div>Languges</div> */}
                    <img  src={arrow} style={{ paddingTop: '0.5vh' }} alt="User" />
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom' sx={{ zIndex: 9999 }}>
                        <Box className='H-Logout-button' >
                            {languages.map((language) =>
                                <div className='button-language' onClick={() => change_language(language)}>
                                    <a> {language.name} </a>
                                </div>
                            )}
                        </Box>
                    </Popper>

                </div>

            </div>

        </Grid>


        

    </Grid>
    </>



    
    
    }
           
            </>
        // <div className="wrapper flex">

        //     <div >
        //         <div className='header_logo_position' >
        //             <a >
        //                 <img className='logo' src={ailert_logo} alt="Logo" />
        //             </a>
        //         </div>
        //         <div className='camera-info' >
        //             <div>{t('header.location', { framework: 'React' })} : {cameraLocation ? cameraLocation : cameraLocationVideoFile ? cameraLocationVideoFile : cameraLocationSnapshotFile}</div>
        //             <div>{t('header.address', { framework: 'React' })}  : {cameraAddress ? cameraAddress : cameraAddressVideoFile ? cameraAddressVideoFile : cameraAddressSnapshotFile}</div>
        //             <div>{date.toLocaleDateString('en-US', options)} {date.toLocaleTimeString()}</div>
        //             <div className='language'>
        //                 <div className='button-language' onClick={() => i18n.changeLanguage('fr')}><a> FR </a></div>|
        //                 <div className='button-language' onClick={() => i18n.changeLanguage('en')}><a> EN </a></div>| 
        //                 <div className='button-language' onClick={() => i18n.changeLanguage('gr')}><a> GR </a></div>
        
        //             </div>
                    

        //         </div>


        //         {window.innerWidth > 500 ? <div className='gif_position'>
        //             <img className='gif_tall' id='alert_statue'
        //                 src={alertResolved == false ? red_gif : blue_gif} alt="Logo" />

        //         </div> : ''}



        //     </div>





        // </div>
    );
}